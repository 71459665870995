import classNames from 'classnames';
import { forwardRef } from 'react';

import { StandardInputProps } from '@tb-core/types/elements/form';

import styles from './styles.module.scss';

const FocusOrValueEmailInput = forwardRef<HTMLInputElement, StandardInputProps>(
    ({ label, ...props }, ref) => (
        <label className={classNames(styles['hidden-label'])}>
            <input
                {...props}
                aria-label={label}
                className={styles['input_padding']}
                ref={ref}
                type="email"
            />
            <span>{label}</span>
        </label>
    )
);

export default FocusOrValueEmailInput;
