const emailAddressRegExp = new RegExp(
    '[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}' +
        '\\@' +
        '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}' +
        '(' +
        '\\.' +
        '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}' +
        ')+'
);

export const validateEmail = (email: string) => emailAddressRegExp.test(email);

export const validateConfirmEmail = (
    confirmEmailValue: string,
    emailValue: string
) => validateEmail(confirmEmailValue) && emailValue === confirmEmailValue;

export const validatePhoneNumber = (phoneNumber: string) => {
    const allowed = /^\d{10}$/;
    return allowed.test(phoneNumber);
};
export const validateZipCode = (zipcode: string) => {
    const allowed = /^\d{5}$/;
    return allowed.test(zipcode);
};
