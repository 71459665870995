import classNames from 'classnames';
import { forwardRef } from 'react';

import FocusOrValueEmailInput from '@tb-core/components/styled/form-controls/focus-or-value-email-input';
import { StandardInputProps } from '@tb-core/types/elements/form';

import styles from './styles.module.scss';

const BorderLabelEmailInput = forwardRef<HTMLInputElement, StandardInputProps>(
    ({ className, label, ...props }, ref) => (
        <div className={classNames(styles['input-border'], className)}>
            <FocusOrValueEmailInput {...props} label={label} ref={ref} />
        </div>
    )
);

export default BorderLabelEmailInput;
