import { track } from '@amplitude/analytics-browser';
import { BaseEvent, EventOptions } from '@amplitude/analytics-types';
import { SyntheticEvent } from 'react';

import SafeAnchor, {
    SafeAnchorProps
} from '@tb-core/components/behavior/safe-anchor';

export interface SafeAnchorWithAmplitudeEventProps extends SafeAnchorProps {
    eventInput: string | BaseEvent;
    eventProperties?: Record<string, any> | undefined;
    eventOptions?: EventOptions;
}

const SafeAnchorWithAmplitudeEvent = ({
    eventInput,
    eventProperties,
    eventOptions,
    onClick,
    ...props
}: SafeAnchorWithAmplitudeEventProps) => {
    const handleClick: (
        event: SyntheticEvent<HTMLAnchorElement, MouseEvent>
    ) => void = event => {
        track(eventInput, eventProperties, eventOptions);

        if (onClick) {
            onClick(event);
        }
    };

    return <SafeAnchor {...props} onClick={handleClick} />;
};

export default SafeAnchorWithAmplitudeEvent;
