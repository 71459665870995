import { RealObject } from '@tb-core/types';
import { ReactNode } from 'react';

export interface InterpolatedComponentsProps {
    model: RealObject<ReactNode>;
    str: string;
}

const InterpolatedComponents = ({
    model,
    str
}: InterpolatedComponentsProps) => {
    const parts = str.split(/({[^{}]*})/);
    return (
        <>
            {parts.map(
                part =>
                    (part[0] === '{' &&
                        model[part.slice(1, part.length - 1)]) ||
                    part
            )}
        </>
    );
};

export default InterpolatedComponents;
