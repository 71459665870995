import { track } from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import {
    ChangeEventHandler,
    FocusEvent,
    FormEventHandler,
    MouseEventHandler,
    useEffect,
    useState
} from 'react';

import SafeAnchorWithAmplitudeEvent from '@tb-core/components/behavior/safe-anchor/with-amplitude-event';
import LazyLoadSvg from '@tb-core/components/composites/lazyload/svg';
import Svg from '@tb-core/components/simple/svg';
import BrandedButton from '@tb-core/components/styled/buttons/branded-button';
import ThemableButton from '@tb-core/components/styled/buttons/themable-button';
import BrandedBorderLabelEmailInput from '@tb-core/components/styled/form-controls/branded-border-label-email-input';
import InputWithError from '@tb-core/components/styled/input-with-error';
import InterpolatedComponents from '@tb-core/components/util/interpolated-components';
import { validateEmail } from '@tb-core/helpers/form/form-validation';
import { useOauth } from '@tb-core/hooks/auth/use-oauth';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';
import { arrangeMagicLink } from '@tb-core/providers/auth/make-token-request';
import { RealObject } from '@tb-core/types';

import styles from './styles.module.scss';

const PasswordlessMain = () => {
    const router = useRouter();
    const [email, setEmail] = useState('');
    const [userHasInteracted, setUserHasInteracted] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState<boolean>();
    const [clickedResend, setClickedResend] = useState(false);
    const [showConfirmMessage, setShowConfirmMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingApple, setIsLoadingApple] = useState(false);
    const isLogin = router.pathname.includes('login');
    const { updateModals } = useLayoutActivityContext();
    const switchAuthEventName = isLogin ? 'Started Signup' : 'Clicked Login';

    const pageProperties = {
        page_path: router?.asPath,
        page_title: isLogin ? 'Login' : 'Register',
        screen_name: !showConfirmMessage
            ? 'Welcome Home'
            : `Check Your Email - ${isLogin ? 'Log in' : 'Sign Up'}`
    };

    const { generic = {}, site = {} } =
        usePageContext().content.pageData.topicMeta || {};

    const {
        alreadyHaveAnAccountLabel,
        appleAuthLabel,
        confirmationMessage,
        confirmationSubtitle,
        confirmationTitle,
        ctaLabel,
        ctaLink,
        dividerLabel,
        emailLabel,
        errorCta,
        invalidEmailMessage,
        redirectUrl,
        requestErrorDescription,
        requestErrorTitle,
        resendLabel,
        resendMessagePostClick,
        resendMessagePreClick,
        switchAuthPageLabel,
        title,
        tooManyRequestsErrorTitle,
        tooManyRequestsErrorDescription
    } = generic;
    const {
        logo: { id }
    } = site;

    const {
        makeSignInAuthorizationRequest,
        makeRegistrationAuthorizationRequest
    } = useOauth({
        redirectUrl
    });

    const showError =
        userHasInteracted && typeof isValidEmail === 'boolean' && !isValidEmail;
    const pageTitle = showConfirmMessage ? confirmationTitle : title;

    const responseHandler = (status?: number) => {
        switch (status) {
            case 200:
                setShowConfirmMessage(true);
                break;
            case 429:
                createModalObject(
                    tooManyRequestsErrorDescription,
                    tooManyRequestsErrorTitle
                );
                break;
            default:
                createModalObject(requestErrorDescription, requestErrorTitle);
        }
    };

    const emailChangeHandler: ChangeEventHandler<HTMLInputElement> = event => {
        setIsValidEmail(validateEmail(event.target.value));
        setEmail(event.target.value);
    };

    const loginClickHandler: FormEventHandler = async event => {
        event.preventDefault();
        setIsLoading(true);

        if (isLogin) {
            track('Clicked Login', pageProperties);
        } else {
            track('Confirmed Sign Up Email', {});
        }

        await sendEmail();
        setIsLoading(false);
    };

    const onBlurEmail = (event: FocusEvent<HTMLInputElement>) => {
        if (!userHasInteracted) {
            setUserHasInteracted(true);
        }
        setIsValidEmail(validateEmail(event.target.value));
    };

    const sendEmail = async () => {
        const res = await arrangeMagicLink(email);
        responseHandler(res.status);
    };

    const createModalObject = (description: string, title: string) => {
        updateModals({
            ['error-modal']: {
                buttonText: errorCta,
                className: styles.modal,
                description,
                showHeader: true,
                title
            }
        });
    };
    const resendEmail = async () => {
        const resendEvent: RealObject = { ...pageProperties };
        // If user has already clicked resend, need to submit a different event
        if (clickedResend) {
            resendEvent.header_text = "Still can't find it";
        } else {
            setClickedResend(true);
        }

        track('Clicked Resend Link', resendEvent);
        await sendEmail();
    };

    const appleClickHandler: MouseEventHandler = async () => {
        setIsLoadingApple(true);

        if (isLogin) {
            track('Clicked Login', pageProperties);
            await makeSignInAuthorizationRequest();
        } else {
            track('Clicked Sign In with Apple', {
                ...pageProperties,
                screen_name: 'Sign Up'
            });
            await makeRegistrationAuthorizationRequest();
        }

        setIsLoadingApple(false);
    };

    const resendButton = (
        <button className={styles['resend-link']} onClick={resendEmail}>
            {resendLabel}
        </button>
    );

    useEffect(() => {
        // Track when user sees the message telling them to check their email
        if (showConfirmMessage) {
            track('Viewed Page/Screen', pageProperties);
        }
    }, [showConfirmMessage]);

    return (
        <div className={styles['page-container']}>
            <div className={styles.container}>
                <LazyLoadSvg svgId={id} height="80px" />
                <h1 className={styles.title}>{pageTitle}</h1>
                {showConfirmMessage ? (
                    <>
                        <div className={styles.subtitle}>
                            {confirmationSubtitle} {email}
                        </div>
                        <div className={styles.message}>
                            {confirmationMessage}
                        </div>
                        <div className={styles.resend}>
                            {InterpolatedComponents({
                                model: { resendButton },
                                str: clickedResend
                                    ? resendMessagePostClick
                                    : resendMessagePreClick
                            })}
                        </div>
                    </>
                ) : (
                    <>
                        <form onSubmit={loginClickHandler}>
                            <div className={styles.email}>
                                <InputWithError
                                    className={styles.input}
                                    error={showError}
                                    message={invalidEmailMessage}
                                >
                                    <BrandedBorderLabelEmailInput
                                        label={emailLabel}
                                        name="email"
                                        onBlur={onBlurEmail}
                                        onChange={emailChangeHandler}
                                        placeholder={emailLabel}
                                        required={true}
                                        value={email}
                                    />
                                </InputWithError>
                            </div>
                            <BrandedButton
                                className={styles.button}
                                disabled={!isValidEmail || isLoading}
                                isLoading={isLoading}
                            >
                                {ctaLabel}
                            </BrandedButton>
                        </form>
                        <div className={styles.divide}>
                            <div className={styles.line}></div>
                            {dividerLabel}
                            <div className={styles.line}></div>
                        </div>
                        <ThemableButton
                            className={styles['apple-button']}
                            disabled={isLoadingApple}
                            onClick={appleClickHandler}
                            theme="root"
                        >
                            <Svg
                                className={styles['apple-icon']}
                                svgId="icon-apple"
                            />
                            {appleAuthLabel}
                        </ThemableButton>
                        <div className={styles['account-label']}>
                            {alreadyHaveAnAccountLabel}
                        </div>
                        <SafeAnchorWithAmplitudeEvent
                            className={styles['resend-link']}
                            eventInput={switchAuthEventName}
                            eventProperties={pageProperties}
                            href={ctaLink}
                        >
                            {switchAuthPageLabel}
                        </SafeAnchorWithAmplitudeEvent>
                    </>
                )}
            </div>
        </div>
    );
};

export default PasswordlessMain;
