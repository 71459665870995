import { forwardRef } from 'react';

import BorderLabelEmailInput from '@tb-core/components/styled/form-controls/border-label-email-input';
import ColorThemable from '@tb-core/components/styled/form-controls/color-themable';
import { StandardInputProps } from '@tb-core/types/elements/form';

const BrandedBorderLabelEmailInput = forwardRef<
    HTMLInputElement,
    StandardInputProps
>((props, ref) => (
    <ColorThemable theme="brand" border={true} inverse={true}>
        {themeProps => (
            <BorderLabelEmailInput {...themeProps} {...props} ref={ref} />
        )}
    </ColorThemable>
));

export default BrandedBorderLabelEmailInput;
